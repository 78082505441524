import {formatter, request} from "@/utils/request";

export namespace ExpenseNonTPM {
  export const Path = {
    Query: '/contractapi/expenseNonTpm/query',
    Export: '/contractapi/expenseNonTpm/export',
    BatchDelete: '/contractapi/expenseNonTpm/batchDelete',
    QueryPaymentForSelect: '/reportapi/expenseNonTpm/queryPaymentForSelect',
    QueryReimbursementForSelect: '/reportapi/expenseNonTpm/queryReimbursementForSelect',
    DownLoadExpenseNonTpmPicker: '/reportapi/expenseNonTpm/downLoadExpenseNonTpmPicker',
    GET: '/contractapi/expenseNonTpm/get'
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.Query, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.Export, formatter(options));
  }

  export async function batchDelete(expenseNonTpmIds: string) {
    const options = {
      method: 'GET',
      body: {
        expenseNonTpmIds: expenseNonTpmIds
      },
    };
    return request<API.ResponseResult<any>>(Path.BatchDelete, formatter(options));
  }

  export async function get(expenseNonTpmId: string) {
    const options = {
      method: 'GET',
      body: {
        expenseNonTpmId: expenseNonTpmId
      },
    };
    return request<API.ResponseResult<any>>(Path.GET, formatter(options));
  }

  export async function queryPaymentForSelect(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.QueryResult<any>>(Path.QueryPaymentForSelect, formatter(options));
  }

  export async function queryReimbursementForSelect(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.QueryResult<any>>(Path.QueryReimbursementForSelect, formatter(options));
  }


}
