import React from 'react';
import {Button, message} from 'antd';
import {WdiModalAppResult} from '../WdiControls';
import {ResponseStatus} from '@/utils/request';
import {Enum} from '@/enums/system';

export const ApplyFooterType = {
  None: 0,
  Draft: 1,
  Submit: 2,
  Validate: 3,
  Delete: 4,
}

const DRAFT_BACK_URL = "/home/index/?Tabs=MyDraft";

export type ApplyFooterProps = {
  hiddenButton?: number[],
  viewEntity?: any;
  doSubmit: (type: number, forceSubmit: boolean, setLoading?: (loading: boolean) => any) => any;
  del?: () => any;
  showWarning?: (result: WdiModalAppResult, callback?: () => any) => any;
  submitBackUrl: string;
  callback?: (result: WdiModalAppResult) => void;
  setLoading?: (loading: boolean) => void;
}

export const ApplyFooter: React.FC<ApplyFooterProps> = (props: ApplyFooterProps) => {

  // 单据提交时调用
  const doSubmit = async (forceSubmit: boolean) => {
    const responseResult = await props.doSubmit(ApplyFooterType.Submit, forceSubmit);
    props?.setLoading?.(false);
    if (responseResult) {
      if (responseResult.code === ResponseStatus.SUCCESS) {
        message.success('提交成功');
        if (props.callback) {
          props.callback(responseResult);
        } else {
          window.location.href = props.submitBackUrl;
        }
      } else {
        if (responseResult.message) {
          message.error(responseResult.message);
          return responseResult;
        } else {
          if (props.showWarning) {
            props.showWarning(responseResult, () => {
              return doSubmit(true);
            });
          }
        }
      }
    }
  }

  return (
    <>
      <div style={{marginTop: 20, float: 'right', paddingBottom: 20}}>
        <div className="ant-space ant-space-horizontal ant-space-align-center" style={{gap: 16}}>
          <Button type='primary' key="delete"
                  hidden={(props.hiddenButton || []).includes(ApplyFooterType.Delete) ? true : false
                  || (props.viewEntity && props.viewEntity.formId) ? false : true}
                  onClick={() => {
                    if (props.del) {
                      if (window.confirm("确定要删除此表单吗？")) {
                        props?.setLoading?.(true);
                        setTimeout(async () => {
                          const responseResult = await props.del?.();
                          props?.setLoading?.(false);
                          if (responseResult) {
                            if (responseResult.code !== ResponseStatus.SUCCESS) {
                              message.error(responseResult.message);
                              return responseResult;
                            } else {
                              message.success('删除成功');
                              if (props.callback) {
                                props.callback(responseResult);
                              } else {
                                // 强制跳转到首页草稿页面
                                window.location.href = DRAFT_BACK_URL;
                              }
                            }
                          }
                        }, 500);

                      }
                    }
                  }}>
            删除
          </Button>
          <Button type='primary' key="draft"
                  hidden={(props.hiddenButton || []).includes(ApplyFooterType.Draft)
                    || (props.viewEntity && props.viewEntity.relatedFormId && props.viewEntity.relationType != Enum.FormRelationType.COPY)}
                  onClick={() => {
                    setTimeout(async () => {
                      const responseResult = await props.doSubmit(ApplyFooterType.Draft, false);
                      props?.setLoading?.(false);
                      if (responseResult) {
                        if (responseResult.code !== ResponseStatus.SUCCESS) {
                          message.error(responseResult.message);
                          return responseResult;
                        } else {
                          message.success('提交成功');
                          // 强制跳转到首页草稿页面
                          window.location.href = DRAFT_BACK_URL;
                        }
                      }
                    }, 500);
                  }}>
            保存成草稿
          </Button>
          <Button type='primary' key="validate"
                  hidden={((props.hiddenButton || []).includes(ApplyFooterType.Validate)) ? true : false}
                  onClick={async () => {
                    const responseResult = await props.doSubmit(ApplyFooterType.Validate, false);
                    props?.setLoading?.(false);
                    if (responseResult) {
                      if (responseResult.code !== ResponseStatus.SUCCESS) {
                        message.error(responseResult.message);
                        return responseResult;
                      } else if (props.showWarning) {
                        props.showWarning(responseResult, async () => {
                          return await doSubmit(true);
                        });
                      }
                    }

                  }}>
            验证
          </Button>
          <Button type='primary' key="submit" onClick={() => {
            doSubmit(false)
          }}>
            提交
          </Button>
        </div>
      </div>
    </>
  );
}

