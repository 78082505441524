import { useEffect, useState } from 'react';
import { WdiModalAppProps, WdiModalProps, wdiCore, WdiModalAppResult } from '@/components/WdiControls';
import { CloseOutlined } from "@ant-design/icons";
import { WdiSelect } from '@/components/WdiControls/WdiSelect';
import { message, Table, Typography, Input, Tooltip, Button } from 'antd';
import { WdiModal } from '@/components/WdiControls/WdiModalForm';
import { ResponseStatus } from '@/utils/request';
import { Staff } from '@/services/basic/staff';
import type { LabeledValue } from 'antd/es/select';
import { WdiTable } from '@/components/WdiControls/WdiTable';

type UserInfo = {
  userId: number;
  userName: string;
}

export type ApproverUserItem = {
  data: UserInfo[];
};

export namespace ApproverUserIds {
  const psplit = (value: string): string[] => {
    const idsArray = value.split(" ");
    const result: string[] = []
    idsArray.forEach((item) => {
      const id = item.trim().replace(/P/g, "");
      if (id) {
        result.push(id);
      }
    })
    return result;
  };

  const parse = (value: string): { ids: string, stepIds: string[][] } => {
    let result: string[] = [];
    const rtvArray: string[][] = [];
    const idsArray = value.split(";");
    idsArray.map(ids => {
      const idSet = psplit(ids);
      if (idSet.length) {
        result = result.concat(idSet);
        rtvArray.push(idSet);
      }
    });
    return { ids: result.join(), stepIds: rtvArray };
  };

  const formatApproverUserItem = (stepIds: string[][], staffs: API.Staff[]) => {
    const itemArray: ApproverUserItem[] = [];
    if (stepIds && stepIds.length && staffs && staffs.length) {
      stepIds.forEach(idArray => {
        const approverUserItem: ApproverUserItem = {
          data: []
        }
        idArray.forEach(id => {
          const staff = staffs.find(item => item?.staffId == Number(id));
          if (staff) {
            approverUserItem.data.push({
              userId: staff.staffId,
              userName: staff.staffName
            });
          }
        });
        itemArray.push(approverUserItem)
      });
    }
    return itemArray;
  };

  export const approverUserItemToText = (data?: ApproverUserItem[]) => {
    if (data) {
      return data.map((item) => item.data.map(userInfo => userInfo.userName).join("/")).join(";");
    }
    return "";
  }

  export const approverUserItemToValue = (data?: ApproverUserItem[]) => {
    if (data) {
      return data.map((item) => item.data.map(userInfo => `P${userInfo.userId}P`).join(" ")).join(";");
    }
    return null;
  }

  export const fetchUserForName = (value: string, callback: (data?: string) => void) => {
    if (wdiCore.String.IsNullOrEmpty(value)) {
      callback();
    }
    const pValue = parse(value);
    if (pValue && pValue.ids) {
      Staff.search({ staffIds: pValue.ids }).then(response => {
        const names = approverUserItemToText(formatApproverUserItem(pValue.stepIds, response.data));
        callback(names);
      })
    }
  };

  export const fetchUserForDataSource = (value: string, callback: (data: ApproverUserItem[]) => void) => {
    if (wdiCore.String.IsNullOrEmpty(value)) {
      callback([]);
    }
    const pValue = parse(value);
    if (pValue && pValue.ids) {
      Staff.search({ staffIds: pValue.ids }).then(response => {
        callback(formatApproverUserItem(pValue.stepIds, response.data));
      })
    }
  }
}

type ItemProps = {
  index: number;
  approvers: ApproverUserItem;
}

const ApproverModalApp: React.FC<WdiModalAppProps> = (props) => {
  const [dataSource, setDataSource] = useState<ItemProps[]>([]);

  const [userInfoLabelInValue, setUserInfoLabelInValue] = useState<LabeledValue[]>([]);

  useEffect(() => {
    const newDataSource: ItemProps[] = [];
    if (props.data) {
      props.data.forEach((item: ApproverUserItem, itemIndex: number) => {
        newDataSource.push({
          approvers: item,
          index: itemIndex
        });
      });
    }
    setDataSource(newDataSource);
  }, [props.data]);

  const del = (key: number) => {
    const newDataSource: ItemProps[] = [];
    let index = 0;
    dataSource.forEach((item, itemIndex) => {
      if (item.index === key) {
      } else {
        item.index = index;
        newDataSource.push(item);
      }
      index++;
    });
    setDataSource(newDataSource);
  };

  const save = () => {
    if (!userInfoLabelInValue || userInfoLabelInValue.length <= 0) {
      message.error(`请选择附加审批人`);
      return;
    }
    if (dataSource.length >= 5) {
      message.error(`附加审批人不能超过5层`);
      return;
    }
    const newDataSource: ItemProps[] = [...dataSource];
    newDataSource.push({
      approvers: {
        data: userInfoLabelInValue.map((item) => {
          return { userId: Number(item.value), userName: String(item.label) }
        })
      },
      index: newDataSource.length
    });
    setDataSource(newDataSource);
    setUserInfoLabelInValue([]);
  };

  const columns = [
    {
      title: '审批人',
      dataIndex: 'approvers',
      editable: true,
      render: (value: any, record: ItemProps) => {
        if (value && value.data && value.data.length) {
          return value.data.map((item: UserInfo) => item.userName).join();
        }
        return "";
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: '65px',
      render: (_: any, record: ItemProps) => (
        <Typography.Link onClick={() => del(record.index)}>
          删除
        </Typography.Link>
      )
    },
  ];

  return (
    <WdiModal
      title={props.title}
      visible={props.visible}
      width={600}
      maskClosable={false}
      destroyOnClose
      onCancel={() => { props.onClose ? props.onClose() : null }}
      onOk={() => {
        const newDataSource: ItemProps[] = [...dataSource];
        if (userInfoLabelInValue && userInfoLabelInValue.length > 0) {
          newDataSource.push({
            approvers: {
              data: userInfoLabelInValue.map((item) => {
                return { userId: Number(item.value), userName: String(item.label) }
              })
            },
            index: newDataSource.length
          });
        }
        if (newDataSource.length > 5) {
          message.error(`附加审批人不能超过5层`);
          return;
        }

        const newData: ApproverUserItem[] = newDataSource.map(item => item.approvers);
        if (props.onSave) {
          props.onSave({ code: ResponseStatus.SUCCESS, data: newData });
        }
      }}
    >
      <WdiTable
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey="index"
        summary={() => (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <WdiSelect
                  mode="multiple"
                  placeholder="请选择附加审批人"
                  showSearch
                  remote
                  labelInValue
                  value={userInfoLabelInValue}
                  onChange={
                    (value) => { setUserInfoLabelInValue(value) }
                  }
                  dataLoading={async (params) => {
                    const responseResult = await Staff.ajaxSearch(Object.assign({}, params));
                    return responseResult.data?.map((item: any) => { return { value: item.staffId, label: item.staffName }; });
                  }}
                />
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <Typography.Link onClick={() => save()}>
                  新增
                </Typography.Link>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </WdiModal>
  );
}

export type ApproverUserApps = {
  readOnly?: boolean;
  value?: ApproverUserItem[];
  onChange?: (value?: ApproverUserItem[]) => void;
};

const ApproverUserApp: React.FC<ApproverUserApps> = (props) => {

  const [modalProps, setModalProps] = useState<WdiModalProps>({ visible: false });
  const onClose = () => {
    setModalProps({ visible: false, title: "", id: null, data: null });
  };

  const onSave = (result?: WdiModalAppResult) => {
    setModalProps({ visible: false, title: "", id: null, data: null });
    if (result && result.code === ResponseStatus.SUCCESS && props.onChange) {
      props.onChange(result.data);
    }
  };

  const newProps: any = {};
  if (props.readOnly) {
    newProps.readOnly = true;
  } else {
    newProps.onClick = () => {
      setModalProps({ visible: true, title: "选择审批人", id: null, data: props.value });
    }
    newProps.allowClear = {
      clearIcon: (
        <Button
          shape='circle'
          style={{ border: "none", width: 20, height: 20 }}
          icon={<CloseOutlined />}
          size="small"
          onClick={() => {
            if (props.onChange) {
              props.onChange([]);
            }
          }}
        />
      )
    }
  }

  return (
    <>
      <Tooltip title={ApproverUserIds.approverUserItemToText(props.value)}>
        <Input
          {...newProps}
          value={ApproverUserIds.approverUserItemToText(props.value)}
        />
      </Tooltip>
      {
        modalProps.visible &&
        <ApproverModalApp
          {...modalProps}
          onClose={onClose}
          onSave={onSave}
        />
      }
    </>
  );
}
export default ApproverUserApp;
