import {useEffect, useState} from 'react';
import {wdiCore, WdiModalAppProps, WdiModalAppResult, WdiModalProps} from '@/components/WdiControls';
import {CloseOutlined} from "@ant-design/icons";
import {Button, Descriptions, Input, Popover, Skeleton, Tooltip} from 'antd';
import {WdiModal} from '@/components/WdiControls/WdiModalForm';
import {ResponseStatus} from '@/utils/request';
import {FilterColumn, GridFilterForm} from '@/components/GridWidget/GridFilterForm';
import {ColumnsType} from 'antd/lib/table/interface';
import {WdiTable} from '@/components/WdiControls/WdiTable';
import {BudgetProject} from '@/services/sales/bp/budgetProject';
import {WdiSelect} from '@/components/WdiControls/WdiSelect';
import {Staff} from '@/services/basic/staff';
import DataStore from '@/components/GridWidget/DataStore';
import {EC} from '@/services/mdm/ec';
import {Product} from '@/services/mdm/product';
import {SoldTo} from '@/services/mdm/soldto';

type TableParams = { filterOptions: any };
export type ProjectItem = {
  budgetProjectId: number;
  codeOrName: string;
};
const ProjectSelectModalApp: React.FC<WdiModalAppProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<API.BudgetProjectPicker[]>([]);
  const [selectedRow, setSelectedRow] = useState<API.BudgetProject>();
  const [selectedRowKey, setSelectedRowKey] = useState<any>();

  const [budgetProjectId, setBudgetProjectId] = useState(0);
  const [data, setData] = useState<API.BudgetProjectTooltip>();

  const [tableParams, setTableParams] = useState<TableParams>({
    filterOptions: null,
  });

  useEffect(() => {
    if (budgetProjectId) {
      BudgetProject.getForTooltip(budgetProjectId).then(async response => {
        if (response.code === ResponseStatus.SUCCESS) {
          setData(response.data);
        }
      });
    }
  }, [budgetProjectId]);

  const setRowClass = (record: API.BudgetProject) => {
    return record.budgetProjectId == selectedRowKey ? 'ant-table-row-selected' : ''
  };

  const getPopoverContent = () => {
    return <div style={{width: 600}}>
      <Descriptions column={1} labelStyle={{width: 100}}>
        <Descriptions.Item label="项目编号">{data?.budgetProject.projectCode}</Descriptions.Item>
        <Descriptions.Item label="项目名称">{data?.budgetProject.projectName}</Descriptions.Item>
        <Descriptions.Item
          label="项目期间">{wdiCore.Date.Join(data?.budgetProject.projectStartDate, data?.budgetProject.projectEndDate)}</Descriptions.Item>
        <Descriptions.Item
          label="活动申请时间">{wdiCore.Date.Join(data?.budgetProject.applyStartDate, data?.budgetProject.applyEndDate)}</Descriptions.Item>
        {data?.budgetProject.limitedChannelIds &&
          <Descriptions.Item label="渠道维度">{data?.budgetProject.limitedChannelNames}</Descriptions.Item>
        }
        {data?.budgetProject.limitedEcIds &&
          <Descriptions.Item label="费用类别维度">{data?.budgetProject.limitedEcNames}</Descriptions.Item>
        }
        {data?.budgetProject.limitedProductIds &&
          <Descriptions.Item label="产品别维度">{data?.budgetProject.limitedProductNames}</Descriptions.Item>
        }
        {data?.budgetProject.limitedShopLevelIds &&
          <Descriptions.Item label="门店等级维度">{data?.budgetProject.limitedShopLevelNames}</Descriptions.Item>
        }
        {data?.budgetProject.limitedSoldToCodes &&
          <Descriptions.Item label="售达方维度">{data?.budgetProject.limitedSoldToNames}</Descriptions.Item>
        }
      </Descriptions>
      <WdiTable
        showPageRows={8}
        columns={[
          {title: '预算中心', key: 'boName', dataIndex: 'boName'},
          {title: '费用类别', key: 'ecName', dataIndex: 'ecName'},
          {title: '分配金额', key: 'amountTotal', dataIndex: 'amountTotal', width: 90},
          {title: '已用金额', key: 'amountUsaged', dataIndex: 'amountUsaged', width: 90},
          {title: '剩余金额', key: 'amountRemain', dataIndex: 'amountRemain', width: 90},
        ]}
        dataSource={DataStore.init(data?.formBudgetInfos)}
        pagination={false}
      />
    </div>;
  };

  const filterColumns: FilterColumn[] = [
    {
      title: '项目名称',
      dataIndex: 'projectName',
      span: 4,
      render: () => {
        return <Input/>;
      }
    },
    {
      title: '项目编号',
      dataIndex: 'projectCode',
      span: 4,
      render: () => {
        return <Input/>;
      },
    },
    {
      title: '创建人',
      dataIndex: 'createdUserId',
      span: 4,
      render: () => {
        return <WdiSelect {...{
          placeholder: "请选择创建人",
          showSearch: true,
          dataLoading: async (params) => {
            const response = await Staff.search(Object.assign({}, params));
            return response.data.map((item) => {
              return {value: item.staffId, label: item.staffName};
            });
          }
        }} />
      },
    },
  ];
  const columns: ColumnsType<any> = [
    {
      title: '项目名称',
      dataIndex: 'projectName',
      ellipsis: true,
      onCell: (record) => {
        return {
          onClick: () => {
            setBudgetProjectId(record.budgetProjectId);
          }
        }
      },
      render: (_, record) => (
        budgetProjectId == record.budgetProjectId ?
          <Popover
            content={getPopoverContent()}
            title={null}
            trigger="click"
            placement="bottomRight"
            defaultVisible
          >
            <span style={{color: '#1296db', cursor: 'pointer'}}>{record.projectName}</span>
          </Popover>
          : <span style={{color: '#1296db', cursor: 'pointer'}}>{record.projectName}</span>
      )
    },
    {
      title: '项目编号',
      width: 150,
      dataIndex: 'projectCode',
      ellipsis: true,
    },
    {
      title: '费用归属',
      width: 150,
      dataIndex: 'bohqId',
      ellipsis: true,
      render: (_, record) => (
        <span>{record.bohqName}</span>
      ),
    },
    {
      title: '创建人',
      width: 100,
      dataIndex: 'createdUserId',
      ellipsis: true,
      render: (_, record) => (
        <span>{record.staffName}</span>
      ),
    },
    {
      title: '项目日期',
      width: 200,
      dataIndex: 'projectDate',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.Date.Join(record.projectStartDate, record.projectEndDate)
      ),
    }
  ];
  const fetchData = (params: TableParams) => {
    setLoading(true);
    const filterOptions: any = {};
    if (params.filterOptions) {
      const {projectName, projectCode, createdUserId} = params.filterOptions;
      if (!wdiCore.String.IsNullOrEmpty(projectName)) {
        filterOptions.projectName = projectName;
      }
      if (!wdiCore.String.IsNullOrEmpty(projectCode)) {
        filterOptions.projectCode = projectCode;
      }
      if (!wdiCore.String.IsNullOrEmpty(createdUserId)) {
        filterOptions.createdUserId = createdUserId;
      }
    }
    const req: any = {
      filterOptions: JSON.stringify(filterOptions),
    };
    BudgetProject.queryForPicker(req).then(responseResult => {
      const data = responseResult.data;
      if (data) {
        setDataSource(data);
        setTableParams({
          filterOptions: params.filterOptions
        });
        setLoading(false);
        setSelectedRowKey(null);
      }
    });
  };
  const doSearch = (filterOptions: any) => {
    fetchData(Object.assign({}, tableParams, {filterOptions}));
  };

  const doSave = () => {
    if (selectedRowKey) {
      const data: ProjectItem = {
        budgetProjectId: selectedRowKey,
        codeOrName: wdiCore.String.Join(selectedRow?.projectCode, selectedRow?.projectName)
      };

      if (props.onSave) props.onSave({code: ResponseStatus.SUCCESS, data: data});
    }
    return true;
  };

  useEffect(() => {
    fetchData(Object.assign({}, tableParams));
  }, []);

  return (
    <WdiModal
      title={props.title}
      visible={props.visible}
      width={1000}
      bodyStyle={{height: 600}}
      onCancel={() => {
        props.onClose ? props.onClose() : null
      }}
      onOk={doSave}
    >
      <GridFilterForm doSeach={doSearch} columns={filterColumns}/>
      <WdiTable
        columns={columns}
        showPageRows={10}
        rowKey="budgetProjectId"
        dataSource={dataSource}
        pagination={{
          defaultPageSize: 10,
        }}
        loading={loading}
        rowClassName={setRowClass}
        onRow={(record: API.BudgetProject) => {
          return {
            onClick: () => {
              setSelectedRow(record);
              setSelectedRowKey(record.budgetProjectId);
            },
            onDoubleClick: () => {
              setSelectedRow(record);
              setSelectedRowKey(record.budgetProjectId);
              doSave();
            },
          }
        }}
      />
    </WdiModal>
  );
};
export type ProjectSelectProps = {
  value?: ProjectItem;
  onChange?: (value?: ProjectItem) => void;
};
//活动申请Prev页专项Select
const ProjectSelectApp: React.FC<ProjectSelectProps> = (props) => {
  const [modalProps, setModalProps] = useState<WdiModalProps>({visible: false});
  const onClose = () => {
    setModalProps({visible: false, title: "", id: null, data: null});
  };

  const onSave = (result?: WdiModalAppResult) => {
    setModalProps({visible: false, title: "", id: null, data: null});
    if (result && result.code === ResponseStatus.SUCCESS && props.onChange) {
      props.onChange(result.data);
    }
  };

  const newProps: any = {
    // readOnly: true,
    onClick: () => {
      setModalProps({visible: true, title: "专项项目查询（双击即可选中）", id: null, data: props.value});
    },
    allowClear: {
      clearIcon: (
        <Button
          shape='circle'
          style={{border: "none", width: 20, height: 20}}
          icon={<CloseOutlined/>}
          size="small"
          onClick={() => {
            if (props.onChange) {
              props.onChange(undefined);
            }
          }}
        />
      )
    }
  };

  return (
    <>
      <Tooltip title={props.value?.codeOrName}>
        <Input
          {...newProps}
          placeholder="请选择专项项目"
          value={props.value?.codeOrName}
        />
      </Tooltip>
      {
        modalProps.visible &&
        <ProjectSelectModalApp
          {...modalProps}
          onClose={onClose}
          onSave={onSave}
        />
      }
    </>
  );
}
export default ProjectSelectApp;

export type ProjectToolTipAppProps = {
  value?: API.BudgetProject;
  onChange?: (value?: API.BudgetProject) => void;
};
const getToolTipContent = (budgetProjectId?: number) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<API.BudgetProjectTooltip>();
  useEffect(() => {
    if (budgetProjectId) {
      BudgetProject.getForTooltip(budgetProjectId).then(async response => {
        if (response.code === ResponseStatus.SUCCESS) {
          const budgetProject = response.data.budgetProject;
          if (budgetProject.limitedEcIds) {
            const responseEC = await EC.search({ecIds: budgetProject.limitedEcIds});
            response.data.budgetProject.limitedEcNames = responseEC.data.map(item => item.ecName).join();
          }
          if (budgetProject.limitedProductIds) {
            const responseProduct = await Product.searchNoRoot({productIds: budgetProject.limitedProductIds});
            response.data.budgetProject.limitedProductNames = responseProduct.data.map(item => item.codeOrName).join()
          }
          if (budgetProject.limitedSoldToCodes) {
            const responseSoldTo = await SoldTo.search({soldToCodes: budgetProject.limitedSoldToCodes});
            response.data.budgetProject.limitedSoldToNames = responseSoldTo.data.map(item => item.codeOrName).join()
          }
          setData(response.data);
          setLoading(false);
        }
      });
    }
  }, [budgetProjectId]);

  const columns = [
    {title: '预算中心', key: 'boName', dataIndex: 'boName'},
    {title: '费用类别', key: 'ecName', dataIndex: 'ecName'},
    {title: '分配金额', key: 'amountTotal', dataIndex: 'amountTotal', width: 90},
    {title: '已用金额', key: 'amountUsaged', dataIndex: 'amountUsaged', width: 90},
    {title: '剩余金额', key: 'amountRemain', dataIndex: 'amountRemain', width: 90},
  ];
  return <div style={{width: 600}}>
    <Skeleton loading={loading} active>
      <Descriptions column={1} labelStyle={{width: 100}}>
        <Descriptions.Item label="项目编号">{data?.budgetProject.projectCode}</Descriptions.Item>
        <Descriptions.Item label="项目名称">{data?.budgetProject.projectName}</Descriptions.Item>
        <Descriptions.Item
          label="项目期间">{wdiCore.Date.Join(data?.budgetProject.projectStartDate, data?.budgetProject.projectEndDate)}</Descriptions.Item>
        <Descriptions.Item
          label="活动申请时间">{wdiCore.Date.Join(data?.budgetProject.applyStartDate, data?.budgetProject.applyEndDate)}</Descriptions.Item>
        {data?.budgetProject.limitedChannelNames &&
          <Descriptions.Item label="渠道类型维度">{data?.budgetProject.limitedChannelNames}</Descriptions.Item>
        }
        {data?.budgetProject.limitedEcNames &&
          <Descriptions.Item label="费用类别维度">{data?.budgetProject.limitedEcNames}</Descriptions.Item>
        }
        {data?.budgetProject.limitedProductNames &&
          <Descriptions.Item label="产品别维度">{data?.budgetProject.limitedProductNames}</Descriptions.Item>
        }
        {data?.budgetProject.limitedShopLevelIds &&
          <Descriptions.Item label="门店等级维度">{data?.budgetProject.limitedShopLevelNames}</Descriptions.Item>
        }
        {data?.budgetProject.limitedSoldToCodes &&
          <Descriptions.Item label="售达方维度">{data?.budgetProject.limitedSoldToNames}</Descriptions.Item>
        }
      </Descriptions>
      <WdiTable showPageRows={8} columns={columns} dataSource={DataStore.init(data?.formBudgetInfos)}
                pagination={false}/>
    </Skeleton>
  </div>;
};
//活动页专项ToolTip
export const ProjectToolTipApp: React.FC<ProjectToolTipAppProps> = (props) => {
  return (
    <Popover
      content={getToolTipContent(props.value?.budgetProjectId)}
      title={null}
      trigger="click"
      placement="bottomRight"
    >
      <Input readOnly value={wdiCore.String.Join(props.value?.projectCode, props.value?.projectName)}
             style={{color: '#1296db', cursor: 'pointer'}}/>
    </Popover>
  );
};
