import React, {useEffect, useState} from 'react';
import {DetailGrid, Utility} from '@/components/GridWidget';
import {NsGrid} from '@/components/GridWidget/GridDefProps';
import {WdiCardTitle, wdiCore, WdiModalAppResult, WdiModalProps} from '@/components/WdiControls';
import {Button} from 'antd';
import {initFieldDisplayFormater, TableColumnRanderFormats} from '@/components/WdiBus/TableColumnRanderFormats'
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import DataStore from '@/components/GridWidget/DataStore';
import {Enum} from '@/enums/system';
import {GridApi} from '@/services/gridapi';
import {FormSs} from '@/services/sales/ss';
import {GroupDetailGrid} from '@/components/GridWidget/GroupDetailGrid';
import SettleDetailEditApp from "@/pages/sales/ss/GeneralSettleApply/components/detail-edit";
import FormSsDetailPriceEditApp, {
  FormSsDetailNoPriceEditApp,
  FormSsDetailSellinEditApp
} from "@/pages/sales/ss/GeneralSettleApply/components/detail-edit";
import {Position} from '@/services/basic/position';
import {ResponseStatus} from '@/utils/request';
import FormSaDetailSelect from "@/pages/sales/ss/GeneralSettleApply/components/FormSaDetailSelect";
import {FormSsDetailImportModalApp} from "@/pages/sales/ss/GeneralSettleApply/components/FormSsDetailImport";
import {toExcel} from "@/components/WdiControls/WdiExcel";

export type DetailForApplyProps = {
  data?: any,
  dataSource: any[],
  onChange?: (dataSource: any) => void,
}
const ModalTypeEnums = {
  None: 0,
  Prev: -1,
  Import: -2,
  //FIXED: 1,
  PRICE: 2,
  NO_PRICE: 1,
  SELLIN: 3,
  AUTO_CALC_FOR_ACTIVITY: 5,
};
export const initRowFieldConfigs = (record: any, ecFieldConfigs: API.EcFieldConfig[] | undefined) => {
  const columns = ["formSaDetailId", "soldToCode", "custSysCode",
    "productBuyNames", "ecName", "platformName", "districtName", "promotionDesc",
    "amountSa", "amountExVat", "remark", "formSaNo", "activityName", "activityStartDateSa", "activityEndDateSa", "activityStartDate", "activityEndDate", "formSsDetailId"];
  if (record.salesTypeName) {
    columns.push("salesType")
  }
  if (record.quantityActual != null) {
    columns.push("quantityActual")
  }
  if (record.quantityActual1 != null) {
    columns.push("quantityActual1")
  }
  if (record.unitExpenseActual1 != null) {
    columns.push("unitExpenseActual1")
  }
  if (record.quantityActual2 != null) {
    columns.push("quantityActual2")
  }
  if (record.unitExpenseActual2 != null) {
    columns.push("unitExpenseActual2")
  }  
  if (record.unitExpense1 != null) {
    columns.push("unitExpense1")
  }
  if (record.unitExpense2 != null) {
    columns.push("unitExpense2")
  }
  if (record.unitExpense2 != null) {
    columns.push("unitExpenseStandard")
  }
  
  columns.push("supplyStartDate", "supplyEndDate", "revenueType", "productLineId"
    , "paymentMethodName", "invoiceTypeName", "vatTypeName", "saRemark")
  return columns;
};

const GeneralSettleDetailsForApplyApp: React.FC<DetailForApplyProps> = (props) => {
  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const [exportModalProps, setExportModalProps] = useState<WdiModalProps>({visible: false});
  const [importModalProps, setImportModalProps] = useState<WdiModalProps>({visible: false});
  const {data, onChange} = props;
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [modalProps, setModalProps] = useState<WdiModalProps>({visible: false});
  const buttonHidden = data.hidden;
  const onImportClose = () => {
    setImportModalProps({visible: false, title: "", id: null});
  }
  const onDetailClose = () => {
    setExportModalProps({visible: false, title: '', id: null, data: null});
  };
  const onImportSave = (uploadResult: any) => {
    if (uploadResult.code == ResponseStatus.SUCCESS) {
      const newDataSource = [...dataSource];
      const result = uploadResult?.data?.data;
      if (result && result.length > 0) {
        if (uploadResult?.data?.exProperty) {
          //是否清空
          onChange?.(DataStore.init(result));
        } else {
          onChange?.(DataStore.addMulti(newDataSource, result));
        }
      }
    }
    setImportModalProps({visible: false, title: "", type: ModalTypeEnums.None, id: null});
  };
  const onModalClose = () => {
    setModalProps({visible: false, title: "", type: ModalTypeEnums.None, id: null});
  };
  const onEditSave = (result?: WdiModalAppResult) => {
    if (result && result.code === ResponseStatus.SUCCESS) {
      const row = result?.data;
      if (result?.data.rowIndex > -1) {
        const record = modalProps.data.row;
        const newItem = {...row};
        newItem.uid = record.uid;
        const newDataSouce = DataStore.edit(dataSource, newItem);
        onChange?.(newDataSouce);
      } else {
        const newItem = {...row};
        onChange?.(DataStore.add(dataSource, newItem));
      }
    }
    setModalProps({visible: false, title: "", type: ModalTypeEnums.None, id: null});
  };
  useEffect(() => {
    GridApi.searchConfig({id: 'FormSsDetail', allowModifyColumns: false}).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: true,
        showExpandedRow: true,
      });
      initFieldDisplayFormater(settings.formSchema.allFields);

      settings.formSchema.allFields.productLineId.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.String.CodeOrName(record.productLineCode, record.productLineName);
      }
      settings.formSchema.allFields.amountExVat.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.amountExVat, 2);
      }
      settings.formSchema.allFields.salesType.getDisplayFormater = (text: any, record: any, index: any) => {
        return record.salesTypeName;
      }
      settings.formSchema.allFields.quantityActual1.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.quantityActual1, 0);
      }
      settings.formSchema.allFields.quantityActual.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.quantityActual, 0);
      }
      settings.formSchema.allFields.quantityActual2.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.quantityActual2, 0);
      }
      settings.formSchema.allFields.unitExpense1.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.unitExpense1, 2);
      }
      settings.formSchema.allFields.unitExpense2.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.unitExpense2, 2);
      }

      settings.formSchema.allFields.amountSa.aggr = TableColumnRanderFormats.sumAggr('amountSa', 2);
      settings.formSchema.allFields.amountExVat.aggr = TableColumnRanderFormats.sumAggr('amountExVat', 2);

      settings.formSchema.allFields.quantityActual1.aggr = TableColumnRanderFormats.sumAggr('quantityActual1', 2);
      settings.formSchema.allFields.quantityActual.aggr = TableColumnRanderFormats.sumAggr('quantityActual', 2);
      settings.formSchema.allFields.quantityActual2.aggr = TableColumnRanderFormats.sumAggr('quantityActual2', 2);

      settings.formSchema.allFields.soldToCode.width = 200;
      settings.formSchema.allFields.custSysCode.width = 200;
      settings.formSchema.allFields.productBuyNames.width = 220;
      settings.formSchema.allFields.ecName.width = 180;
      settings.formSchema.allFields.amountExVat.width = 150;
      settings.formSchema.allFields.amountSa.width = 150;
      settings.formSchema.allFields.formSaDetailId.width = 120;

      Utility.setActionCallback(settings.formSchema.actions,
        'Update',
        async (grid: any, action: any, record: any) => {
          const context = grid.getContext();
          setModalProps({
            visible: true,
            title: '费用核销',
            type: record.revenueType,
            id: record.formSaDetailId,
            data: {
              row: record,
              pageTypeId: context.params.pageTypeId,
              rows: context.dataSource,
              rowIndex: DataStore.getRowIndex(context.dataSource, record.uid),
              formSs: data,
            }
          });
        },
      );

      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    setDataSource(props.dataSource)
  }, [props.dataSource])


  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs: (record: any) => {
      return initRowFieldConfigs(record, data.ecFieldConfigs);
    },
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
    },
  };
  return (
    <>
      <WdiPanel
        title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='费用明细信息'/>}
        extra={
          <>
            <Button hidden={buttonHidden} type='primary' onClick={() => {
              setExportModalProps({
                visible: true,
                title: `导出需要核销的活动明细`,
                data: {bohqId: data.bohqId, pageTypeId: data.pageTypeId}
              });
            }}
                    style={{float: 'right', marginRight: 10}}>
              导出活动明细
            </Button>
            <Button hidden={buttonHidden} type='primary' onClick={() => {
              setImportModalProps({
                title: '核销信息导入',
                visible: true,
                data: {details: dataSource, bohqId: data.bohqId, pageTypeId: data.pageTypeId}
              });
            }}
                    style={{float: 'right', marginRight: 10}}>
              核销信息导入
            </Button>

          </>
        }
      >
        {isLoaded && importModalProps.visible &&
          <FormSsDetailImportModalApp
            {...importModalProps}
            onClose={onImportClose}
            onSave={onImportSave}
          />
        }
        {isLoaded && exportModalProps.visible &&
          <FormSaDetailSelect
            {...exportModalProps}
            onClose={onDetailClose}
            onSave={onDetailClose}
          />
        }
        {modalProps.type == ModalTypeEnums.PRICE &&
          <FormSsDetailPriceEditApp
            {...modalProps}
            onSave={onEditSave}
            onClose={onModalClose}
          />}
        {modalProps.type == ModalTypeEnums.NO_PRICE &&
          <FormSsDetailNoPriceEditApp
            {...modalProps}
            onSave={onEditSave}
            onClose={onModalClose}
          />}
        {modalProps.type == ModalTypeEnums.SELLIN &&
          <FormSsDetailSellinEditApp
            {...modalProps}
            onSave={onEditSave}
            onClose={onModalClose}
          />}
        {isLoaded && <DetailGrid params={data} {...pageGridSettings}/>}
      </WdiPanel>
    </>
  );
}
export default GeneralSettleDetailsForApplyApp;

export const GeneralSettleDetailsForApprovalPanel: React.FC<DetailForApplyProps> = (props) => {
  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const [modalProps, setModalProps] = useState<WdiModalProps>({visible: false});
  const [dataSource, setDataSource] = useState<any[]>([]);
  const {data, onChange} = props;

  useEffect(() => {
    if (!data.formSs) return;
    Promise.all([GridApi.searchConfig({id: "FormSsDetialApprval", allowModifyColumns: true}),
      Position.access({useCaseId: Enum.UseCase.SS, operateTypeIds: [Enum.OperateType.MODIFY_AMOUNT]})
    ]).then((dataArray: any[]) => {
      const responseResult = dataArray[0];

      const settings: any = Object.assign({}, responseResult.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: true,
        showExpandedRow: true,
      });
      initFieldDisplayFormater(settings.formSchema.allFields);
      settings.formSchema.allFields.quantityActual1.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.quantityActual1, 0);
      }
      settings.formSchema.allFields.quantityActual.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.quantityActual, 0);
      }
      settings.formSchema.allFields.quantityActual2.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.quantityActual2, 0);
      }
      settings.formSchema.allFields.unitExpenseActual1.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.unitExpenseActual1, 2);
      }
      settings.formSchema.allFields.unitExpenseActual2.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.unitExpenseActual2, 2);
      }
      settings.formSchema.allFields.unitExpense1.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.unitExpense1, 2);
      }
      settings.formSchema.allFields.unitExpense2.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.unitExpense2, 2);
      }
      settings.formSchema.allFields.unitExpenseStandard.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.AmountToString(record.unitExpenseStandard, 2);
      }

      settings.formSchema.allFields.amountSa.aggr = TableColumnRanderFormats.sumAggr('amountSa', 2);
      settings.formSchema.allFields.amountExVat.aggr = TableColumnRanderFormats.sumAggr('amountExVat', 2);

      settings.formSchema.allFields.quantityActual1.aggr = TableColumnRanderFormats.sumAggr('quantityActual1', 2);
      settings.formSchema.allFields.quantityActual.aggr = TableColumnRanderFormats.sumAggr('quantityActual', 2);
      settings.formSchema.allFields.quantityActual2.aggr = TableColumnRanderFormats.sumAggr('quantityActual2', 2);

      settings.formSchema.allFields.soldToCode.width = 200;
      settings.formSchema.allFields.custSysCode.width = 200;
      settings.formSchema.allFields.productBuyNames.width = 220;
      settings.formSchema.allFields.ecName.width = 180;
      settings.formSchema.allFields.amountExVat.width = 150;
      settings.formSchema.allFields.amountSa.width = 150;
      settings.formSchema.allFields.formSaDetailId.width = 120;
      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, [data.formSs]);

  const columns = [
    {name: "formSaDetailId", displayName: "活动明细编号"},
    {name: "soldToCode", displayName: "售达方编号"},
    {name: "soldToName", displayName: "售达方名称"},
    {name: "custSysCode", displayName: "客户集团编号"},
    {name: "custSysName", displayName: "客户集团名称"},
    {name: "productBuyNames", displayName: "产品别"},
    {name: "ecName", displayName: "费用项"},
    {name: "platformName", displayName: "分子平台"},
    {name: "districtName", displayName: "省份"},
    {name: "promotionDesc", displayName: "活动机制"},
    {name: "amountSa", displayName: "申请金额"},
    {name: "amountExVat", displayName: "核销金额"},
    {name: "remark", displayName: "核销备注"},
    {name: "formSaNo", displayName: "活动单号"},
    {name: "activityName", displayName: "活动名称"},
    {name: "activityStartDateSa", displayName: "活动开始日期", type: Enum.FieldType.DATE_TIME},
    {name: "activityEndDateSa", displayName: "活动截止日期", type: Enum.FieldType.DATE_TIME},
    {name: "quantityActual", displayName: "系统计算实际销量"},
    {name: 'salesTypeName', displayName: '销量类型'},
    {name: "quantityActual1", displayName: "一促实际销量"},
    {name: "unitExpenseActual1", displayName: "一促实际单听补贴"},
    {name: "quantityActual2", displayName: "二促实际销量"},
    {name: "unitExpenseActual2", displayName: "二促实际单听补贴"},
    {name: "unitExpense1", displayName: "一促计划单听补贴"},
    {name: "unitExpense2", displayName: "二促计划单听补贴"},
    {name: "unitExpenseStandard", displayName: "单听补贴标准"},
    {name: "quantityForecast1", displayName: "一促预估销量"},
    {name: "pricePosAfter1", displayName: "一促零售价"},
    {name: "quantityForecast2", displayName: "二促预估销量"},
    {name: "pricePosAfter2", displayName: "二促零售价"},
    {name: "proQuantityRate", displayName: "总促销销量占比"},
    {name: "proQuantityRate1", displayName: "一促销量占比"},
    {name: "proQuantityRate2", displayName: "二促销量占比"},
    {name: "unitExpenseSellin", displayName: "sellin单听补贴"},
    {name: "quantityForecastSellin", displayName: "sellin预估销量"},
    {name: "activityStartDate", displayName: "明细活动开始日期", type: Enum.FieldType.DATE_TIME},
    {name: "activityEndDate", displayName: "明细活动截止日期", type: Enum.FieldType.DATE_TIME},
    {name: "supplyStartDate", displayName: "明细供货开始日期", type: Enum.FieldType.DATE_TIME},
    {name: "supplyEndDate", displayName: "明细供货截止日期", type: Enum.FieldType.DATE_TIME},
    {name: "revenueTypeName", displayName: "核算类型"},
    {name: "salesTypeName", displayName: "销量类型"},
    {name: "productLineCode", displayName: "产品线编号"},
    {name: "productLineName", displayName: "产品线名称"},
    {name: "paymentMethodName", displayName: "支付方式"},
    {name: "invoiceTypeName", displayName: "发票类型"},
    {name: "vatTypeName", displayName: "税率类型"},
    {name: "saRemark", displayName: "活动申请备注"},
    {name: "formSsDetailId", displayName: "核销明细编号"},
  ];
  const exportDetails = () => {
    const formSpSheet = {
      data: dataSource,
      names: columns,
      sheetName: "费用明细信息",
    };
    toExcel({
      name: "活动核销费用明细导出_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
      sheetProps: [formSpSheet]
    });
  }

  useEffect(() => {
    setDataSource(props.dataSource)
  }, [props.dataSource])

  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs: (record: any) => {
      return initRowFieldConfigs(record, data.ecFieldConfigs);
    },
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
    },
  };
  return (
    <>
      {modalProps.visible &&
        <SettleDetailEditApp
          {...modalProps}
          onSave={(result) => {
            onChange?.(DataStore.edit(dataSource, result?.data));
            setModalProps({visible: false, data: null})
          }}
          onClose={() => setModalProps({visible: false, data: null})}
        />}
      <WdiPanel collapsed={true} {...props} title={<WdiCardTitle title='费用明细信息'/>}
                defaultCollapsed={false}
                extra={
                  <>
                    <Button type='primary' onClick={(e) => {
                      e.stopPropagation();
                      exportDetails();
                    }}>
                      导出
                    </Button>
                  </>
                }
      >
        {isLoaded && <DetailGrid params={data}  {...pageGridSettings} />}
      </WdiPanel>
    </>
  );
}

export type DetailForGroupProps = {
  formId: number,
}

export const GeneralSettleDetailsGroupForApprovalApp: React.FC<DetailForGroupProps> = (props) => {
  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});

  useEffect(() => {
    GridApi.searchConfig({id: 'SSDetailGroup', allowModifyColumns: true}).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.None,
        dataLoading: async (params: any) => {
          return await FormSs.getFormSsDetailsGroup(Object.assign({}, params));
        },
        queryParamaters: {
          formId: props.formId
        },
        additionalColumns: ["amountSa", "amount"]
      });

      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true
    }
  };

  return (
    <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='费用统计信息'/>} collapsed={true}>
      {isLoaded && <GroupDetailGrid {...pageGridSettings} />}
    </WdiPanel>
  );
};
